import React from 'react'
import { Link } from 'gatsby'
import { compact } from 'lodash'

import Timestamp from './Timestamp'

interface Props {
  episodeSlug: string
  timestamps:
    | readonly ({
        label: string | null
        time: string | null
      } | null)[]
    | null
}

/**
 * List timestamps each linking to to the relevant podcast page with the episode
 * automatically starting at the timestamp.
 */
export const TimestampLinks: React.FC<Props> = (props) => {
  const { episodeSlug, timestamps } = props

  if (timestamps == null) {
    return null
  }

  return (
    <div>
      {compact(timestamps).map(({ time, label }, index) =>
        label != null && time != null ? (
          <Link
            key={time}
            to={`/podcast/${episodeSlug}/`}
            state={{
              initialTime: time,
            }}
          >
            <Timestamp
              time={time}
              label={label}
              first={index == 0}
              interactive
            />
          </Link>
        ) : null,
      )}
    </div>
  )
}
