import * as styles from './Timestamp.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  time: string
  label: string
  first: boolean
  onClick?(): void
  interactive: boolean
}

const Timestamp: React.FC<Props> = (props) => {
  const { time, label, first, onClick, interactive } = props

  return (
    <div
      className={classNames(styles.container, {
        [styles.first]: first,
        [styles.interactive]: interactive,
      })}
      onClick={() => {
        if (onClick != null) {
          onClick()
        }
      }}
    >
      <div className={styles.time}>
        {interactive && <div className={styles.playIcon}>▶</div>}
        {time}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default Timestamp
