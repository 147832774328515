module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/anthonymattox/Repositories/lucky-paper/plugins/gatsby-remark-lucky-paper","id":"3ef747d9-2b57-5733-932d-5902b1adbc98","name":"gatsby-remark-lucky-paper","version":"1.0.0","modulePath":"/Users/anthonymattox/Repositories/lucky-paper/plugins/gatsby-remark-lucky-paper/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/anthonymattox/Repositories/lucky-paper/node_modules/gatsby-remark-images","id":"5ba1f063-e669-5041-8685-b77c4687e34b","name":"gatsby-remark-images","version":"7.14.0","modulePath":"/Users/anthonymattox/Repositories/lucky-paper/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/anthonymattox/Repositories/lucky-paper/node_modules/gatsby-remark-smartypants","id":"d48194e5-b344-5f22-b93d-76045ee44e88","name":"gatsby-remark-smartypants","version":"6.14.0","modulePath":"/Users/anthonymattox/Repositories/lucky-paper/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/anthonymattox/Repositories/lucky-paper","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JNLZZTH0GX","UA-150336475-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lucky Paper","short_name":"Lucky Paper","start_url":"/","background_color":"#ffffff","theme_color":"#1b2433","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"adee4bd7cdd925e41a2b0d28e9bbe25f"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
