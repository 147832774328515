import React from 'react'

export const IconPlay: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={30}
      height={30}
    >
      <polygon
        fill="currentColor"
        points="24.28 15 8.91 6.13 8.91 23.87 24.28 15"
      />
    </svg>
  )
}
