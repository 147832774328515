import * as styles from './Controls.module.scss'

import React, { memo } from 'react'

import Logotype from 'components/layout/Logotype'

import { Cube, Cluster } from './data/data'
import { CubeSelection } from './data/useCubeSelection'
import { CardSelection } from './data/useCardSelection'
import { SearchMode } from './search/SearchMode'

import * as Icon from 'components/maps/shared/icons'
import CardSearch from './search/CardSearch'
import CubeSearch from './search/CubeSearch'
import CubeDetails from './CubeDetails'
import SelectedCard from './SelectedCard'
import FAQs from './FAQs'
import SetSurveysBanner from './SetSurveysBanner'
import Settings from './settings/Settings'

import { useSearch } from './search/useSearch'

const searchAttributes = ['name', 'owner']

interface Props {
  searchMode: SearchMode
  setSearchMode(value: SearchMode): void
  cubes: Cube[]
  clusters: {
    [key: string]: Cluster
  }
  cubeSelection: CubeSelection
  cardSelection: CardSelection
  scaleByFollowers: boolean
  setScaleByFollowers(value: boolean): void
  zoomToCube(cube: Cube): void
}

const Controls: React.FC<Props> = (props) => {
  const {
    searchMode,
    setSearchMode,
    cubes,
    clusters,
    cubeSelection,
    cardSelection,
    scaleByFollowers,
    setScaleByFollowers,
    zoomToCube,
  } = props

  const onCubeSelected = (cube: Cube | null, pin: boolean) => {
    if (!pin) {
      cubeSelection.setSelected(cube)
    }

    if (cube != null) {
      if (pin) {
        cubeSelection.pin(cube)
      }
      zoomToCube(cube)
    }
  }

  // This is called here so the index isn't rebuilt when the card search is
  // re-added
  const search = useSearch({
    data: cubes,
    id: 'id',
    searchAttributes: searchAttributes,
    sortByAttribute: 'num_followers',
    max: 50,
  })

  return (
    <div>
      <div className={styles.primary}>
        <h1 className={styles.heading}>
          <Logotype subtitle="Cube Map" />
        </h1>

        <div className={styles.searchWrapper}>
          <div className={styles.searchIcon}>
            <Icon.Search />
          </div>
          {searchMode === 'cubes' ? (
            <CubeSearch
              cubes={cubes}
              search={search}
              onCubeSelected={onCubeSelected}
              cubeSelection={cubeSelection}
              setSearchMode={setSearchMode}
            />
          ) : (
            <CardSearch
              cardSelection={cardSelection}
              setSearchMode={setSearchMode}
            />
          )}
          <div className={styles.settings}>
            <Settings
              searchMode={searchMode}
              setSearchMode={setSearchMode}
              scaleByFollowers={scaleByFollowers}
              setScaleByFollowers={setScaleByFollowers}
              selectedCubes={cubeSelection.all}
            />
          </div>
        </div>
      </div>

      <SetSurveysBanner />

      {searchMode === 'cards' && cardSelection.selectedCard && (
        <div className={styles.card}>
          <SelectedCard
            cardSelection={cardSelection}
            totalCubes={cubes.length}
          />
        </div>
      )}

      {cubeSelection.all.length > 0 && (
        <div className={styles.cubes}>
          {cubeSelection.all.length > 5 && (
            <div className={styles.summary}>
              {cubeSelection.all.length} Cubes,{' '}
              <button
                className={styles.reset}
                onClick={cubeSelection.clearSelection}
              >
                Clear
              </button>
            </div>
          )}
          {cubeSelection.all.map((cube) => (
            <CubeDetails
              key={cube.id}
              cube={cube}
              clusters={clusters}
              cubeSelection={cubeSelection}
              zoomToCube={zoomToCube}
            />
          ))}
        </div>
      )}

      <div className={styles.faqs}>
        <FAQs />
      </div>
      <div className={styles.mobileSpacer} />
    </div>
  )
}

export default memo(Controls)
