import React from 'react'

import { buildInitialCards } from '../data/SimulationState'

import { FullCube } from '../components/FullCube'
import { uniformShufflePile } from '../data/transformations'

const cards = uniformShufflePile(buildInitialCards(260, 1), 0)

const simulationState = {
  cards,
  pileCounts: [cards.length],
  pilePositions: [0],
  columns: 1,
}

export const ExampleShuffled: React.FC = () => {
  return <FullCube simulationState={simulationState} />
}
