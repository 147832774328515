import { random } from 'lodash'

export function twoDistinctRandoms(min: number, max: number) {
  const a = random(min, max, false)
  let b = random(min, max - 1, false)

  if (b >= a) {
    b++
  }

  return [a, b]
}
