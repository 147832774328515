import { chunk, random } from 'lodash'

export function split<T>(values: T[], chunks: number): T[][] {
  return chunk(values, Math.round(values.length / chunks))
}

export function riffle<T>(values: T[]): T[] {
  const stacks = split(values, 2)
  const result = [] as T[]

  while (result.length < values.length) {
    result.push(...stacks[random(0, 1)].splice(0, 1))
  }

  return result
}
