import { useCallback, useMemo } from 'react'
import * as JSSearch from 'js-search'
import { orderBy } from 'lodash'

// Using discouraged `Object` to match the types in js-search.
// eslint-disable-next-line @typescript-eslint/ban-types
export const useSearch = <T extends Object>(options: {
  data: T[]
  id: string
  searchAttributes: string[]
  sortByAttribute?: string
  max?: number
}): ((query: string) => T[]) => {
  const { data, id, searchAttributes, sortByAttribute, max } = options

  const searchIndex = useMemo(() => {
    const searchIndex = new JSSearch.Search(id)
    searchAttributes.forEach((attribute) => {
      searchIndex.addIndex(attribute)
    })
    searchIndex.addDocuments(data)
    return searchIndex
  }, [data, id, searchAttributes])

  const search = useCallback(
    (query: string) => {
      let results = searchIndex.search(query) as any

      if (sortByAttribute != null) {
        results = orderBy(results, sortByAttribute, 'desc')
      }

      if (max != null) {
        results = results.slice(0, max)
      }

      return results
    },
    [max, searchIndex, sortByAttribute],
  )

  return search
}
