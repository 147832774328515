import { shuffle, sortBy } from 'lodash'

import { Card } from '../SimulationState'

/**
 * Uniformly randomize all the cards in a specific pile.
 */
export function uniformShufflePile(cards: Card[], pileIndex: number) {
  const collectionIndices = cards.reduce((result, card, index) => {
    if (card.pile == pileIndex) {
      result.push({ index, position: card.position })
    }

    return result
  }, [] as { index: number; position: number }[])

  const riffled = shuffle(sortBy(collectionIndices, (c) => c.position))

  for (const [index, riffledCard] of riffled.entries()) {
    cards[riffledCard.index].position = index
  }

  return cards
}
