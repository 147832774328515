// title: Upcoming MTG Cube Events — Lucky Paper
// frameCardName: Gathering Throng
// frameCardSet: SNC
// lastUpdatedDate: 2024-05-03

import * as styles from './events.module.css'

import { events } from 'src/data/events'

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import * as Typography from 'components/typography'
import * as Table from 'components/tables'

interface Props {
  pageContext: any
}

const upcomingEvents = events
  .filter((event) => new Date(event.sortDate) > new Date())
  .sort((a, b) => a.sortDate.localeCompare(b.sortDate))

type Event = {
  title: string
  dates: string
  sortDate: string
  location: string
  size?: string
  infoUrl?: string
  infoDescription?: string
  discordUrl?: string
}

type PastEventsGroupedByYear = {
  year: number
  events: Event[]
}[]

const pastEventsGroupedByYear: PastEventsGroupedByYear = events
  .filter((event) => new Date(event.sortDate) < new Date())
  .reduce((acc: PastEventsGroupedByYear, event) => {
    const year = new Date(event.sortDate).getFullYear()
    let yearGroup = acc.find((group) => group.year === year)

    if (!yearGroup) {
      yearGroup = { year, events: [] }
      acc.push(yearGroup)
    }

    yearGroup.events.push(event)
    return acc
  }, [])
  .sort((a, b) => b.year - a.year)
  .map((group) => ({
    ...group,
    events: group.events.sort(
      (a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime(),
    ),
  }))

const AboutPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#0562F3"
      includeNewsletterSignup
    >
      <article className={styles.container}>
        <Typography.PrimaryHeading>
          Upcoming Cube Events
        </Typography.PrimaryHeading>
        <Typography.Paragraph>
          The following events are <i>not</i> affiliated with or endorsed by
          Lucky Paper — this is a community resource to keep track of upcoming
          Cube events around the world. If you&rsquo;re organizing a Cube event
          and would like to be listed here please reach out to{' '}
          <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a> with all of
          the relevant details.
        </Typography.Paragraph>
        <Table.Table>
          <Table.TableRow>
            <Table.TableHeaderCell>Event Name</Table.TableHeaderCell>
            <Table.TableHeaderCell>Dates</Table.TableHeaderCell>
            <Table.TableHeaderCell>Location</Table.TableHeaderCell>
            <Table.TableHeaderCell>Size</Table.TableHeaderCell>
            <Table.TableHeaderCell>Tickets &amp; Info</Table.TableHeaderCell>
            <Table.TableHeaderCell>Discord</Table.TableHeaderCell>
          </Table.TableRow>
          {upcomingEvents.map((event, index) => (
            <Table.TableRow key={index}>
              <Table.TableDataCell>
                <b>{event.title}</b>{' '}
                {event.soldOut && (
                  <span className={styles.soldOutBadge}>Sold Out</span>
                )}
              </Table.TableDataCell>
              <Table.TableDataCell>{event.dates}</Table.TableDataCell>
              <Table.TableDataCell>{event.location}</Table.TableDataCell>
              <Table.TableDataCell>
                {event.size ? event.size : 'N/A'}
              </Table.TableDataCell>
              <Table.TableDataCell>
                {event.infoUrl && event.infoDescription ? (
                  <a href={event.infoUrl}>{event.infoDescription}</a>
                ) : event.infoDescription ? (
                  <div>{event.infoDescription}</div>
                ) : (
                  'N/A'
                )}
              </Table.TableDataCell>
              <Table.TableDataCell>
                {event.discordUrl ? (
                  <a href={event.discordUrl}>Join Server</a>
                ) : (
                  'N/A'
                )}
              </Table.TableDataCell>
            </Table.TableRow>
          ))}
        </Table.Table>

        <Typography.SecondaryDivider />

        <Typography.SecondaryHeading>Past Events</Typography.SecondaryHeading>

        {pastEventsGroupedByYear.map(({ year, events }) => (
          <div key={year}>
            <Typography.TertiaryHeading>{year}</Typography.TertiaryHeading>

            <Table.Table>
              <Table.TableRow>
                <Table.TableHeaderCell>Event Name</Table.TableHeaderCell>
                <Table.TableHeaderCell>Dates</Table.TableHeaderCell>
                <Table.TableHeaderCell>Location</Table.TableHeaderCell>
                <Table.TableHeaderCell>Size</Table.TableHeaderCell>
                <Table.TableHeaderCell>Info</Table.TableHeaderCell>
                <Table.TableHeaderCell>Discord</Table.TableHeaderCell>
              </Table.TableRow>
              {events.map((event: Event, index: number) => (
                <PastEventRow event={event} key={index} />
              ))}
            </Table.Table>
          </div>
        ))}
      </article>
    </Layout>
  )
}

const PastEventRow: React.FC<{
  event: Event
}> = (props) => {
  const { event } = props
  return (
    <Table.TableRow>
      <Table.TableDataCell>
        <b>{event.title}</b>
      </Table.TableDataCell>
      <Table.TableDataCell>{event.dates}</Table.TableDataCell>
      <Table.TableDataCell>{event.location}</Table.TableDataCell>
      <Table.TableDataCell>
        {event.size ? event.size : 'N/A'}
      </Table.TableDataCell>
      <Table.TableDataCell>
        {event.infoUrl && event.infoDescription ? (
          <a href={event.infoUrl}>{event.infoDescription}</a>
        ) : (
          'N/A'
        )}
      </Table.TableDataCell>
      <Table.TableDataCell>
        {event.discordUrl ? <a href={event.discordUrl}>Join Server</a> : 'N/A'}
      </Table.TableDataCell>
    </Table.TableRow>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Upcoming MTG Cube Events — Lucky Paper',
        description: 'Upcoming Magic: the Gathering Cube events.',
      }}
    />
  )
}

export default AboutPage
