import * as styles from './SearchForm.module.css'

import React from 'react'

export const PodcastSearchForm: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>Search Episodes</div>
      <form className={styles.form} action="/podcast/search/">
        <input
          className={styles.input}
          type="text"
          placeholder="Try 'lands', 'playtesting', or 'theory'..."
          name="q"
        />

        <button className={styles.submitButton} type="submit">
          Search
        </button>
      </form>
    </div>
  )
}
