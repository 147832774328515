import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as transformations from '../data/transformations'
import { useAnimationMutex } from '../data/useAnimationMutex'
import { useSimulationState, Card } from '../data/SimulationState'

export function useBroadcastAnimation(
  options: {
    cardCount?: number
    piles?: number
    columns?: number
    shufflesPerPile?: number
    animationID?: string
    simultaneousShuffling?: boolean
  } = {},
) {
  const {
    cardCount = 540,
    piles = 4,
    columns = 12,
    shufflesPerPile = 3,
    animationID,
    simultaneousShuffling = false,
  } = options
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const { playing, play, pause } = useAnimationMutex(animationID)

  const stateRef = useRef(0)
  const remixRef = useRef(0)

  const { simulationState, updateSimulationState, resetState } =
    useSimulationState({ cardCount, piles, columns })

  const [complete, setComplete] = useState(false)

  const reset = useCallback(() => {
    stateRef.current = 0
    pause()
    setComplete(false)
    resetState()
    remixRef.current = 0
  }, [pause, resetState])

  useEffect(() => {
    stateRef.current = 0
    pause()
    setComplete(false)
    resetState()
    remixRef.current = 0
  }, [piles, cardCount, pause, shufflesPerPile, resetState])

  // Build an array of all the steps of the animation. Each step has a function
  // to mutate the simulation state, timing info to update the activity being
  // tracked, and an animation duration to trigger the next step.
  const steps = useMemo(() => {
    const result = []

    // Riffle shuffle each pile
    for (let pileIndex = 0; pileIndex < piles; pileIndex++) {
      for (
        let shuffleIndex = 0;
        shuffleIndex < shufflesPerPile;
        shuffleIndex++
      ) {
        result.push({
          function: (cards: Card[]) =>
            transformations.rifflePile(cards, pileIndex),
          animationDuration: simultaneousShuffling
            ? 0
            : shuffleIndex === shufflesPerPile - 1
            ? 500
            : 200,
          time: 15,
          riffles: 1,
        })
      }
    }

    // Stop the animation after all piles are shuffled after the third remixing.
    result.push({
      shouldComplete: () => {
        return remixRef.current >= 3
      },
      function: (cards: Card[]) => {
        if (remixRef.current >= 3) {
          pause()
          setComplete(true)
        }
        return cards
      },
    })

    // Broadcast piles
    for (let sourceIndex = 0; sourceIndex < piles; sourceIndex++) {
      for (let destIndex = 0; destIndex < piles; destIndex++) {
        const endOfPile = destIndex == piles - 1

        result.push({
          function: (cards: Card[]) =>
            transformations.moveCards(cards, sourceIndex, destIndex + piles, {
              fraction: endOfPile ? undefined : 1 / (piles - destIndex),
            }),
          animationDuration: endOfPile ? 300 : 30,
          time: 25 / (piles * piles),
        })
      }
    }

    // Move piles back form the second row to the first
    result.push({
      function: (cards: Card[]) => {
        remixRef.current++

        for (let index = 0; index < piles; index++) {
          transformations.moveCards(cards, index + piles, index)
        }
        return cards
      },
      animationDuration: 500,
      remixes: 1,
    })

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardCount, piles, shufflesPerPile])

  useEffect(() => {
    if (playing) {
      const action = () => {
        const state = stateRef.current

        const step = steps[state % steps.length]

        const shouldComplete =
          step.shouldComplete != null && step.shouldComplete()

        updateSimulationState(step.function([...simulationState.cards]), step)

        stateRef.current++

        // Redundant but trying to patch a timing issue where one extra step is performed
        if (!complete && !shouldComplete) {
          timeoutIDRef.current = setTimeout(action, step.animationDuration)
        }
      }

      action()

      return () => {
        timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
      }
    } else {
      timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing])

  const playAndResetIfComplete = useCallback(() => {
    if (complete) {
      reset()
      setTimeout(play, 1000)
    } else {
      play()
    }
  }, [complete, play, reset])

  return {
    playing,
    simulationState,
    actions: {
      play: playAndResetIfComplete,
      pause,
      reset,
    },
  }
}
