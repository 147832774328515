import * as styles from './ArticleTemplate.module.scss'

import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { compact } from 'lodash'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import Markdown from 'components/markdown/Markdown'

import TableOfContents from 'components/article-elements/TableOfContents'
import { ArticleSettingsProvider } from 'src/components/article-elements/ArticleSettingsContext'

interface Props extends PageProps<Queries.ArticleTemplateQuery> {
  pageContext: any
}

const ArticleTemplate: React.FC<Props> = (props) => {
  const post = props.data.mdx

  if (post == null || post.frontmatter == null) {
    return null
  }

  return (
    <Layout
      {...props.pageContext}
      accentColor={post.frontmatter.accentColor}
      includeNewsletterSignup
    >
      <article className={styles.container}>
        <div className={styles.header}>
          <h2>
            <Typography.TertiaryHeading>
              {post.frontmatter.series}
            </Typography.TertiaryHeading>
          </h2>
          <h1>
            <Typography.PrimaryHeading>
              {post.frontmatter.title}
            </Typography.PrimaryHeading>
          </h1>
          <div className={styles.meta}>
            {post.frontmatter.formattedDate}
            {post.frontmatter.authors && (
              <>
                {' '}
                —{' '}
                {compact(post.frontmatter.authors).reduce(
                  (result, author, index) => [
                    ...result,
                    index === 0 ? null : <> &amp; </>,
                    author.link ? (
                      <a href={author.link} key={index}>
                        {author.name}
                      </a>
                    ) : (
                      <React.Fragment key={index}>{author.name}</React.Fragment>
                    ),
                  ],
                  [] as React.ReactNode[],
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.content}>
          {post.frontmatter.tableOfContents != null && (
            <TableOfContents
              tableOfContents={post.frontmatter.tableOfContents}
              large={post.frontmatter.largeTOC}
            />
          )}

          <ArticleSettingsProvider>
            <Markdown>{post.body}</Markdown>
          </ArticleSettingsProvider>
        </div>
        <Typography.Divider />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        series
        excerpt
        authors {
          name
          link
        }
        authorTwitter
        ogImage
        formattedDate: date(formatString: "MMMM Do, YYYY")
        date
        lastUpdatedDate
        accentColor
        tableOfContents {
          name
          slug
          children {
            name
            slug
          }
        }
        largeTOC
      }
      fields {
        slug
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  const post = props.data.mdx

  if (post == null || post.frontmatter == null) {
    return null
  }

  const { frontmatter } = post

  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: `${frontmatter.title} — Lucky Paper`,
        description: frontmatter.excerpt ?? undefined,
        ogImageURL: frontmatter.ogImage
          ? `https://luckypaper.co/og-images/${frontmatter.ogImage}`
          : undefined,
        author: frontmatter.authors?.map((author) => author?.name)?.join(' & '),
        twitterCreator: frontmatter.authorTwitter ?? undefined,
        ogType: 'article',
        articleSection: 'Gaming',
        articleTag: 'Magic: the Gathering, Cube, Trading Card Games',
        publishedAt: frontmatter.date ? new Date(frontmatter.date) : undefined,
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'Article',
        name: frontmatter.title,
        headline: frontmatter.title,
        url: props.pageContext.url,
        datePublished: frontmatter.date ?? undefined,
        dateModified:
          frontmatter.lastUpdatedDate ?? frontmatter.date ?? undefined,
        abstract: frontmatter.excerpt ?? undefined,
        author: {
          '@type': 'Person',
          name: frontmatter.authors?.map((author) => author?.name)?.join(' & '),
        },
        publisher: {
          '@type': 'Organization',
          name: 'Lucky Paper',
          slogan: ' A love letter to Magic: the Gathering',
        },
        image: 'https://luckypaper.org/lucky-paper.jpg',
      }}
    />
  )
}

export default ArticleTemplate
