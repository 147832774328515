export const initialStats = {
  time: 0,
  riffles: 0,
  remixes: 0,
}

export type Stats = typeof initialStats

export function addStats(a: Stats, b: Partial<Stats>) {
  return {
    time: a.time + (b.time ?? 0),
    riffles: a.riffles + (b.riffles ?? 0),
    remixes: a.remixes + (b.remixes ?? 0),
  }
}
