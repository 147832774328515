import React from 'react'

import { TextColumn } from 'components/typography'
import { ResponsiveSVG } from 'components/utils/ResponsiveSVG'

import { SimulationState } from '../data/SimulationState'

import { FullCubeSVG } from './FullCubeSVG'

interface Props {
  simulationState: Pick<SimulationState, 'cards' | 'pilePositions'>
}

export const FullCube: React.FC<Props> = (props) => {
  const { simulationState } = props

  return (
    <TextColumn>
      <ResponsiveSVG height="60px">
        {({ width, height }) => (
          <FullCubeSVG
            x={0}
            y={0}
            width={width}
            height={height}
            simulationState={simulationState}
          />
        )}
      </ResponsiveSVG>
    </TextColumn>
  )
}
