import React, { useMemo, useState } from 'react'

import * as controls from 'components/controls'

import { useBroadcastAnimation } from './useBroadcastAnimation'

import { ShufflerLayout } from '../components/ShufflerLayout'
import { ShuffleVisualization } from '../components/ShuffleVisualization'

interface Props {
  simpleControls?: boolean
}

export const ShuffleSimulatorBroadcast: React.FC<Props> = (props) => {
  const { simpleControls = false } = props

  const [cardCount, setCardCount] = useState(540)
  const [piles, setPiles] = useState(4)
  const [shuffles, setShuffles] = useState(3)

  const { playing, simulationState, actions } = useBroadcastAnimation({
    cardCount,
    piles,
    columns: piles,
    shufflesPerPile: shuffles,
  })

  const caption = useMemo(() => {
    return `One player shuffles ${
      piles === 4 ? 'quarters' : `1/${piles}s`
    } of the cube. After ${shuffles} shuffles, the piles are "broadcast" ${
      piles === 4 ? 'in quarters ' : `1/${piles}s`
    } into ${piles} new piles.`
  }, [piles, shuffles])

  return (
    <ShufflerLayout
      simulationState={simulationState}
      playing={playing}
      actions={actions}
      title="The Broadcast Shuffle"
      caption={caption}
      controls={
        <controls.ControlGroup>
          {!simpleControls && (
            <controls.Select
              value={cardCount.toString()}
              options={[
                { name: '180', label: '180 Cards' },
                { name: '360', label: '360 Cards' },
                { name: '540', label: '540 Cards' },
                { name: '750', label: '750 Cards' },
              ]}
              onChange={(event) => {
                setCardCount(parseInt(event.currentTarget.value))
              }}
            />
          )}

          <controls.Select
            value={piles.toString()}
            options={[
              { name: '4', label: '4 Piles' },
              { name: '5', label: '5 Piles' },
              { name: '6', label: '6 Piles' },
              { name: '7', label: '7 Piles' },
              { name: '8', label: '8 Piles' },
            ]}
            onChange={(event) => {
              setPiles(parseInt(event.currentTarget.value))
            }}
          />

          {!simpleControls && (
            <controls.Select
              value={shuffles.toString()}
              options={[
                { name: '1', label: '1 Shuffles' },
                { name: '2', label: '2 Shuffles' },
                { name: '3', label: '3 Shuffles' },
                { name: '5', label: '5 Shuffles' },
                { name: '7', label: '7 Shuffles' },
                { name: '10', label: '10 Shuffles' },
                { name: '20', label: '20 Shuffles' },
              ]}
              onChange={(event) => {
                setShuffles(parseInt(event.currentTarget.value))
              }}
            />
          )}
        </controls.ControlGroup>
      }
    >
      <ShuffleVisualization
        simulationState={simulationState}
        animating={playing}
        height={Math.max((cardCount / piles) * 2 + 30, 120)}
      />
    </ShufflerLayout>
  )
}
