import * as styles from './EpisodeList.module.scss'

import React, { useMemo } from 'react'
import { sortBy } from 'lodash'

import { EpisodeRow } from './EpisodeRow'

interface Props {
  readonly podcasts: ReadonlyArray<{
    readonly id: string
    readonly frontmatter: {
      readonly title: string
      readonly date: string | null
      readonly excerpt: string | null
      readonly episodeSlug: string | null
      readonly episodeNumber: number | null
      readonly episodeOrder: number | null
      readonly tags: ReadonlyArray<string | null> | null
    } | null
  }>
  activeTag?: string
}

export const EpisodeList: React.FC<Props> = (props) => {
  const { podcasts, activeTag } = props

  const sortedEpisodes = useMemo(() => {
    // Gatsby's sorting seems to not work completely consistently
    return sortBy(
      podcasts,
      (episode) => episode.frontmatter?.episodeOrder,
    ).reverse()
  }, [podcasts])

  return (
    <div className={styles.container}>
      {sortedEpisodes.map((podcast) => (
        <EpisodeRow key={podcast.id} podcast={podcast} activeTag={activeTag} />
      ))}
    </div>
  )
}
