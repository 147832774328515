import * as d3 from 'd3'

export const colors = [
  '#6b21c1',
  '#245bbc',
  '#3dacd8',
  '#50cc50',
  '#14590a',
  '#d3b818',
  '#e57225',
]

const keyColors = ['#6b21c1', '#3dacd8', '#d3b818', '#e5288b']

export const colorScale = d3.scaleLinear([0, 0.33, 0.66, 1], keyColors)
