import * as styles from './PlaybackControls.module.scss'

import React from 'react'

import { IconPause } from '../icons/Pause'
import { IconPlay } from '../icons/Play'
import { IconReset } from '../icons/Reset'

interface Props {
  playing: boolean
  play: () => void
  pause: () => void
  reset: () => void
}

export const PlaybackControls: React.FC<Props> = (props) => {
  const { playing, play, pause, reset } = props

  return (
    <div className={styles.container}>
      <button
        className={styles.controlButton}
        title={playing ? 'Pause Simulation' : 'Run Simulation'}
        onClick={() => {
          playing ? pause() : play()
        }}
      >
        {playing ? (
          <>
            <IconPause />
          </>
        ) : (
          <>
            <IconPlay />
          </>
        )}
      </button>
      <button
        title="Reset"
        className={styles.controlButton}
        onClick={() => reset()}
      >
        <IconReset />
      </button>
    </div>
  )
}
