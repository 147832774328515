import * as styles from './TestingList.module.scss'

import React, { useState } from 'react'

import * as controls from 'components/controls'
import * as forms from 'components/forms'
import * as Typography from 'components/typography'

import CardHover from 'components/cards/CardHover'

import { Set } from 'utils/surveySetData'

import { CardRatings } from '../utils/useCardRatings'

interface Props {
  set: Set
  ratings: CardRatings
}

const TestingList: React.FC<Props> = (props) => {
  const { set, ratings } = props

  const [expanded, setExpanded] = useState(false)

  const cardNames = ratings.included.map((rating) => rating.cardName).join('\n')
  const cardNamesWithRatings = ratings.included
    .map((rating) => `${rating.rating} - ${rating.cardName}`)
    .join('\n')

  return (
    <div>
      {!expanded && (
        <Typography.TextBlock>
          <controls.TextButton onClick={() => setExpanded(true)}>
            View your testing list
          </controls.TextButton>
        </Typography.TextBlock>
      )}

      {expanded && (
        <Typography.TextColumn>
          <div>
            Testing {ratings.included.length}{' '}
            {ratings.included.length === 1 ? 'Card' : 'Cards'} -{' '}
            <controls.TextButton onClick={() => setExpanded(false)}>
              Hide List
            </controls.TextButton>
          </div>

          <div>
            <div className={styles.copyOptions}>
              <forms.CopyButton copyText={cardNames}>
                Copy Card Names
              </forms.CopyButton>
              <forms.CopyButton copyText={cardNamesWithRatings}>
                Copy Full Ratings
              </forms.CopyButton>
            </div>
          </div>

          {ratings.included.map((rating) => {
            if (rating.rating == null) {
              return null
            }

            return (
              <div key={rating.cardName} className={styles.card}>
                {rating.rating.toFixed(1)} -{' '}
                <CardHover card={rating.cardName} set={set.code}>
                  {rating.cardName}
                </CardHover>
              </div>
            )
          })}
        </Typography.TextColumn>
      )}
    </div>
  )
}

export default TestingList
