import * as styles from './PodcastTemplate.module.scss'

import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { compact } from 'lodash'

import { parseDuration } from 'utils/parseDuration'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'

import { PlayerContextProvider } from 'components/podcast/PlayerContext'
import { PodcastContent } from 'components/podcast/PodcastContent'
import { EpisodeNavigation } from 'components/podcast/EpisodeNavigation'
import { PodcastHeading } from 'components/podcast/PodcastHeading'
import { PodcastPlayer } from 'components/podcast/PodcastPlayer'
import { TagList } from 'components/podcast/TagList'

interface Props extends PageProps<Queries.PodcastTemplateQuery> {
  pageContext: {
    url: string
    title: string
    initialView?: 'cards' | 'timestamps'
  }
}

const PodcastTemplate: React.FC<Props> = (props) => {
  const {
    data: { podcast, next, previous },
    pageContext: { initialView },
  } = props

  const initialTimeString = (props.location.state as any)?.initialTime

  const initialTime =
    initialTimeString != null ? parseDuration(initialTimeString) : null

  const episode = podcast?.frontmatter

  if (podcast == null || episode == null) {
    return null
  }

  return (
    <Layout
      {...props.pageContext}
      accentColor={episode.accentColor}
      includeNewsletterSignup
      extraFooterSpace
    >
      <PlayerContextProvider initialTime={initialTime}>
        <section>
          <EpisodeNavigation
            previousEpisode={previous?.frontmatter}
            nextEpisode={next?.frontmatter}
          />

          <PodcastHeading>
            <div className={styles.subhead}>
              {episode.episodeNumber
                ? `Episode ${episode.episodeNumber}`
                : 'Bonus Episode'}{' '}
              — {episode.formattedDate}
            </div>

            <div className={styles.title}>{episode.title}</div>

            {episode.tags != null && episode.tags.length > 0 && (
              <div>
                <TagList tags={episode.tags} />
              </div>
            )}
          </PodcastHeading>

          <PodcastContent podcast={podcast} initialView={initialView} />

          <PodcastPlayer
            episode={{
              title: episode.title,
              audioPath: episode.audioPath,
              timestamps: compact(episode.timestamps),
            }}
          />
        </section>
      </PlayerContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query PodcastTemplate($episodeOrder: Int!, $next: Int, $previous: Int) {
    podcast: mdx(frontmatter: { episodeOrder: { eq: $episodeOrder } }) {
      body
      frontmatter {
        title
        excerpt
        formattedDate: date(formatString: "MMMM Do, YYYY")
        date
        episodeNumber
        audioPath
        accentColor
        episodeSlug
        tags
        timestamps {
          time
          label
        }
        cards {
          groups {
            heading
            subheading
            display
            cards {
              name
              set
              id
              emphasized
            }
            subgroups {
              pack
              pick
              column
              cards {
                name
                emphasized
                count
              }
            }
          }
        }
        annotationCredit {
          name
          link
        }
      }
    }
    next: mdx(
      frontmatter: { episodeOrder: { eq: $next } }
      fields: { type: { eq: "podcast-notes" } }
    ) {
      frontmatter {
        title
        episodeSlug
      }
    }
    previous: mdx(
      frontmatter: { episodeOrder: { eq: $previous } }
      fields: { type: { eq: "podcast-notes" } }
    ) {
      frontmatter {
        title
        episodeSlug
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  const {
    data: { podcast },
    pageContext,
  } = props

  if (podcast?.frontmatter == null) {
    return null
  }

  if (pageContext.initialView === 'cards') {
    return (
      <PageMetaData
        url={pageContext.url}
        metaData={{
          title: `${pageContext.title} — Lucky Paper Radio`,
          description: `View every Magic card mentioned in Episode ${podcast.frontmatter.episodeNumber} of Lucky Paper Radio.`,
          ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        }}
      />
    )
  }

  if (pageContext.initialView === 'timestamps') {
    return (
      <PageMetaData
        url={pageContext.url}
        metaData={{
          title: `${pageContext.title} — Lucky Paper Radio`,
          description: `Linked timestamps for topics in Episode ${podcast.frontmatter.episodeNumber} of Lucky Paper Radio.`,
          ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        }}
      />
    )
  }

  return (
    <PageMetaData
      url={pageContext.url}
      metaData={{
        title: `${pageContext.title} — Lucky Paper Radio`,
        description: podcast.frontmatter.excerpt ?? undefined,
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'PodcastEpisode',
        name: pageContext.title,
        description: podcast.frontmatter.excerpt ?? undefined,
        url: pageContext.url,
        image: {
          '@type': 'ImageObject',
          url: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
          name: 'Lucky Paper Radio',
        },
        creator: [
          {
            '@type': 'Person',
            name: 'Andy Mangold',
          },
          {
            '@type': 'Person',
            name: 'Anthony Mattox',
          },
        ],
        musicBy: {
          '@type': 'Person',
          name: 'DJ James Nasty',
          url: 'https://www.facebook.com/jamesnasty/',
        },
        associatedMedia:
          podcast.frontmatter.audioPath != null
            ? {
                '@type': 'MediaObject',
                contentUrl: podcast.frontmatter.audioPath,
              }
            : undefined,
        partOfSeries: {
          '@type': 'PodcastSeries',
          name: 'Lucky Paper Radio',
          description:
            'Lucky Paper Radio is a podcast about Magic: the Gathering hosted by lifelong friends Andy Mangold and Anthony Mattox. Focused primarily on sandbox formats like Cube, which are free of banlists, rotation, and net decking, the show is about the best parts of Magic and how to get the most out of the game.',
          url: 'https://luckypaper.co/podcast/',
          webFeed: 'https://anchor.fm/s/29f49e0c/podcast/rss',
          genre: 'Games',
          startDate: '2020-07-05',
          creator: [
            {
              '@type': 'Person',
              name: 'Andy Mangold',
            },
            {
              '@type': 'Person',
              name: 'Anthony Mattox',
            },
          ],
        },
      }}
    />
  )
}

export default PodcastTemplate
