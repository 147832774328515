import React, { useRef, useEffect } from 'react'

interface Props {
  children: HTMLElement
}

/**
 * Container to inject a plain DOM element into a React tree.
 */
const ElementContainer: React.FC<Props> = (props) => {
  const { children } = props

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const container = ref.current

    if (container == null) {
      throw new Error('Unable to inject element into component tree')
    }

    container.appendChild(children)

    return () => {
      const element = container.firstChild
      element && container.removeChild(element)
    }
  }, [children])

  return <div ref={ref} />
}

export default ElementContainer
