import { range } from 'lodash'

import { Card } from '../SimulationState'

/**
 * Get the starting positions, in order, of each pile in the fill set. e.g. if
 * all the piles where stacked together where would each pile start. The first
 * always being 0.
 */
export function getPilePositions(cards: Card[]) {
  const piles = Math.max(...cards.map((card) => card.pile))

  const pileCounts = cards.reduce((result, card) => {
    result[card.pile]++
    return result
  }, Array(piles + 1).fill(0) as number[])

  const pilePositions = range(0, pileCounts.length - 1).reduce(
    (result, index) => {
      result.push((result[result.length - 1] ?? 0) + (pileCounts[index] ?? 0))

      return result
    },
    [0] as number[],
  )

  return { pileCounts, pilePositions }
}
