import { random, sortBy } from 'lodash'

import { Card } from '../SimulationState'

import { countPile } from './countPile'

/**
 * Move a number of cards from the end of one pile to another. Specify either an
 * exact number or approximate fraction of cards which will be slightly
 * randomized. If none is specified, all cards are moved.
 *
 * Mutates the collection.
 */
export function moveCards(
  cards: Card[],
  sourcePile: number,
  destinationPile: number,
  options: {
    count?: number
    fraction?: number
  } = {},
) {
  let count =
    options.count != null
      ? options.count
      : options.fraction != null
      ? Math.ceil(countPile(cards, sourcePile) * options.fraction)
      : null

  if (count != null) {
    count += random(-3, 3, false)
  }

  const collectionIndices = cards.reduce((result, card, index) => {
    if (card.pile == sourcePile) {
      result.push({ index, position: card.position })
    }

    return result
  }, [] as { index: number; position: number }[])

  const collectionIndicesToMove = sortBy(collectionIndices, (c) => c.position)
    .slice(count != null ? -1 * count : 0)
    .map((c) => c.index)

  const destinationPileCount = cards.filter(
    (c) => c.pile === destinationPile,
  ).length

  for (const [index, collectionIndex] of collectionIndicesToMove.entries()) {
    cards[collectionIndex].pile = destinationPile
    cards[collectionIndex].position = destinationPileCount + index
  }

  return cards
}
