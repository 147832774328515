import * as styles from './ShuffleComparison.module.scss'

import React from 'react'

import * as Typography from 'components/typography'

import { ResponsiveSVG } from 'components/utils/ResponsiveSVG'

import { useChaosAnimation } from './simulations/useChaosAnimation'
import { usePassLeftAnimation } from './simulations/usePassLeftAnimation'
import { useBroadcastAnimation } from './simulations/useBroadcastAnimation'

import { FullCubeSVG } from './components/FullCubeSVG'
import { PlaybackControls } from './components/PlaybackControls'

export const ShuffleComparison: React.FC = () => {
  const chaos = useChaosAnimation({
    cardCount: 540,
    piles: 8,
    columns: 4,
    animationID: 'shuffle-comparison',
  })

  const passLeft = usePassLeftAnimation({
    cardCount: 540,
    piles: 8,
    columns: 8,
    animationID: 'shuffle-comparison',
  })

  const broadcast = useBroadcastAnimation({
    cardCount: 540,
    piles: 8,
    columns: 8,
    simultaneousShuffling: true,
    animationID: 'shuffle-comparison',
  })

  return (
    <div className={styles.container}>
      <Typography.TextColumn>
        <PlaybackControls
          playing={chaos.playing}
          play={() => {
            chaos.actions.play()
            passLeft.actions.play()
            broadcast.actions.play()
          }}
          pause={() => {
            chaos.actions.pause()
            passLeft.actions.pause()
            broadcast.actions.pause()
          }}
          reset={() => {
            chaos.actions.reset()
            passLeft.actions.reset()
            broadcast.actions.reset()
          }}
        />
      </Typography.TextColumn>

      <div>
        <ResponsiveSVG height="40px">
          {({ width }) => (
            <FullCubeSVG
              simulationState={chaos.simulationState}
              width={width}
              height={40}
            />
          )}
        </ResponsiveSVG>

        <Typography.Caption>
          Casual Chaos: 8 Players shuffling and randomly exchanging half their
          cards
        </Typography.Caption>

        <ResponsiveSVG height="40px">
          {({ width }) => (
            <FullCubeSVG
              simulationState={passLeft.simulationState}
              width={width}
              height={40}
            />
          )}
        </ResponsiveSVG>

        <Typography.Caption>
          Pass Left: 8 Players shuffle their stacks and pass half their cards to
          the left every 3 shuffles
        </Typography.Caption>

        <ResponsiveSVG height="40px">
          {({ width }) => (
            <FullCubeSVG
              simulationState={broadcast.simulationState}
              width={width}
              height={40}
            />
          )}
        </ResponsiveSVG>

        <Typography.Caption>
          Broadcast Shuffle: 8 players shuffling and redistributing their cards
          evenly to all shuffflers
        </Typography.Caption>
      </div>
    </div>
  )
}
