import React, { useEffect, useRef } from 'react'

/**
 * A `useEffect` hook throttled to the screen refresh rate which wont be
 * executed more than once per frame.
 *
 * Note the ESLint hooks check might not work on this.
 */
export const useRAFThrottledEffect = (
  callback: () => void,
  dependencies: React.DependencyList,
) => {
  const lastRenderTime = useRef<DOMHighResTimeStamp | null>(null)

  useEffect(() => {
    requestAnimationFrame((timestamp) => {
      if (timestamp === lastRenderTime.current) {
        return
      }

      lastRenderTime.current = timestamp

      callback()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
