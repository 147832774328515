import React from 'react'

import { useChaosAnimation } from './useChaosAnimation'

import { ShufflerLayout } from '../components/ShufflerLayout'
import { ShuffleVisualization } from '../components/ShuffleVisualization'

const cardCount = 540

export const ShuffleSimulatorChaos: React.FC = () => {
  const { playing, simulationState, actions } = useChaosAnimation({
    cardCount: 540,
    piles: 8,
    columns: 4,
  })

  return (
    <ShufflerLayout
      simulationState={simulationState}
      playing={playing}
      actions={actions}
      title="Casual Chaos"
      caption='"Traditional Cube Shuffle" - 8 players shuffle and occasionally exchange half their cards.'
    >
      <ShuffleVisualization
        simulationState={simulationState}
        animating={playing}
        height={(cardCount / 8) * 2 + 50}
      />
    </ShufflerLayout>
  )
}
