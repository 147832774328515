import { FeaturedLinks } from "../../../../src/components/article-elements/FeaturedLinks.tsx";
import { ExampleInitial } from 'src/components/shuffle-simulator/state-examples/Initial.tsx';
import { ExampleShuffled } from 'src/components/shuffle-simulator/state-examples/Shuffled.tsx';
import { ExamplePartiallyShuffled } from 'src/components/shuffle-simulator/state-examples/PartiallyShuffled.tsx';
import { ShuffleSimulatorChaos } from 'src/components/shuffle-simulator/simulations/Chaos.tsx';
import { ShuffleSimulatorPassLeft } from 'src/components/shuffle-simulator/simulations/PassLeft.tsx';
import { ShuffleSimulatorBroadcast } from 'src/components/shuffle-simulator/simulations/Broadcast.tsx';
import { ShuffleComparison } from 'src/components/shuffle-simulator/ShuffleComparison.tsx';
import * as React from 'react';
export default {
  FeaturedLinks,
  ExampleInitial,
  ExampleShuffled,
  ExamplePartiallyShuffled,
  ShuffleSimulatorChaos,
  ShuffleSimulatorPassLeft,
  ShuffleSimulatorBroadcast,
  ShuffleComparison,
  React
};