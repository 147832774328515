import * as styles from './Overlay.module.scss'

import React from 'react'

import Indicator from './Indicator'

const LoadingOverlay: React.FC = () => {
  return (
    <div className={styles.container}>
      <Indicator />
    </div>
  )
}

export default LoadingOverlay
