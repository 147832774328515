import { sortBy } from 'lodash'

import { Card } from '../SimulationState'

import { riffle } from './riffle'

/**
 * Riffle shuffle the cards in one specific pile.
 */
export function rifflePile(cards: Card[], pileIndex: number) {
  const collectionIndices = cards.reduce((result, card, index) => {
    if (card.pile == pileIndex) {
      result.push({ index, position: card.position })
    }

    return result
  }, [] as { index: number; position: number }[])

  const riffled = riffle(sortBy(collectionIndices, (c) => c.position))

  for (const [index, riffledCard] of riffled.entries()) {
    cards[riffledCard.index].position = index
  }

  return cards
}
