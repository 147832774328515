import * as styles from './SheetTemplateLinks.module.scss'

import React from 'react'

import { TextColumn } from 'components/typography'

const SheetTemplateLinks: React.FC = () => {
  return (
    <TextColumn>
      <div className={styles.container}>
        <div className={styles.heading}>
          Get the template and host your own Rotisserie Draft!
        </div>
        <div className={styles.links}>
          <a
            href="https://docs.google.com/spreadsheets/d/1eQXIBS-C5I1ramqJ43ow8QMV-hvNXtW_Zon_ZQuxvRs/edit?usp=sharing"
            className={styles.link}
          >
            Rotisserie Draft Template &rarr;
          </a>

          <a
            href="https://docs.google.com/spreadsheets/d/1f9_NTUOTofClcZNy3neHx7M1RwaaIlWpi_PhnMv2Ek0/edit?usp=sharing"
            className={styles.link}
          >
            For up to 12 Players &rarr;
          </a>
        </div>
      </div>
    </TextColumn>
  )
}

export default SheetTemplateLinks
