import * as styles from './Blockquote.module.css'

import React from 'react'

interface Props {
  children: string
}

const Blockquote: React.FC<Props> = (props) => {
  const { children } = props

  return <blockquote className={styles.container}>{children}</blockquote>
}

export default Blockquote
