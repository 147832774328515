import * as styles from './RadioGroup.module.scss'

import React, { useCallback } from 'react'
import classNames from 'classnames'

import { RadioButton } from './RadioButton'

interface Props {
  value: string
  options: { name: string; label: string }[]
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  inline?: boolean
}

export const RadioGroup: React.FC<Props> = (props) => {
  const { value, options, onChange, inline = false } = props

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        onChange(event)
      }
    },
    [onChange],
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.inline]: inline,
      })}
    >
      {options.map((option) => (
        <label key={option.name}>
          <RadioButton
            name={option.name}
            value={option.name}
            onChange={handleChange}
            checked={value === option.name}
          />{' '}
          {option.label}
        </label>
      ))}
    </div>
  )
}
