import { useCallback, useMemo, useState } from 'react'
import { range } from 'lodash'

import * as transformations from './transformations'

import { colorScale } from './colors'
import { addStats } from './stats'

export function buildInitialCards(cardCount: number, pileCount: number) {
  const pileSize = Math.ceil(cardCount / pileCount)
  const highlightCount = cardCount / pileCount

  return range(0, cardCount).map((index) => {
    return {
      id: index,
      highlighted: index < highlightCount,
      colorGroup: Math.floor(index / pileSize),
      color: colorScale(index / cardCount),
      pile: Math.floor(index / pileSize),
      position: index % pileSize,
      fractionalPosition: index / cardCount,
    }
  })
}

export type Card = ReturnType<typeof buildInitialCards>[0]

function buildInitialState(
  cardCount: number,
  pileCount: number,
  columns: number,
) {
  const cards = buildInitialCards(cardCount, pileCount)
  return {
    cards,
    columns,
    time: 0,
    riffles: 0,
    remixes: 0,
  }
}

export function useSimulationState(options: {
  cardCount?: number
  piles?: number
  columns?: number
}) {
  const { cardCount = 540, piles = 4, columns = 12 } = options

  const [simulationState, setSimulationState] = useState(() => {
    return buildInitialState(cardCount, piles, columns)
  })

  const resetState = useCallback(() => {
    setSimulationState(buildInitialState(cardCount, piles, columns))
  }, [cardCount, columns, piles])

  const updateSimulationState = useCallback(
    (
      cards: Card[],
      stats: {
        time?: number
        riffles?: number
        remixes?: number
      },
    ) => {
      setSimulationState((previousValue) => ({
        cards,
        columns: previousValue.columns,
        ...addStats(previousValue, stats),
      }))
    },
    [],
  )

  const { pileCounts, pilePositions } = useMemo(() => {
    return transformations.getPilePositions(simulationState.cards)
  }, [simulationState.cards])

  return {
    simulationState: {
      ...simulationState,
      pileCounts,
      pilePositions,
    },
    updateSimulationState,
    resetState,
  }
}

export type SimulationState = ReturnType<
  typeof useSimulationState
>['simulationState']
