import * as styles from './ResponsiveSVG.module.scss'

import React from 'react'
import useMeasure from 'react-use-measure'

interface Props {
  height?: string
  aspectRatio?: string
  children(size: { width: number; height: number }): React.ReactNode
}

export const ResponsiveSVG: React.FC<Props> = (props) => {
  const { height, aspectRatio, children } = props

  const [ref, bounds] = useMeasure({
    debounce: 250,
  })

  return (
    <div
      className={styles.container}
      ref={ref}
      style={{
        height,
        aspectRatio,
      }}
    >
      {bounds.width > 0 && (
        <svg
          className={styles.svg}
          viewBox={`0 0 ${bounds.width} ${bounds.height}`}
        >
          {children(bounds)}
        </svg>
      )}
    </div>
  )
}
