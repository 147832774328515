import { useCallback, useMemo } from 'react'
import * as d3 from 'd3'

import { isTouchDevice } from 'utils/useIsTouchDevice'

import { Transform } from './transform'

export interface Point {
  x: number
  y: number
}

/**
 * Efficiently search for the closest element in a set to a point using a quad
 * tree.
 */
export const useSearchTree = <T extends Point>(
  items: T[],
  width: number,
  height: number,
  zoomTransform: d3.ZoomTransform,
  extent: [[number, number], [number, number]],
) => {
  const searchTree: d3.Quadtree<T> = useMemo(() => {
    return d3.quadtree(
      items,
      (d) => d.x,
      (d) => d.y,
    )
  }, [items])

  const itemAtPoint = useCallback(
    (event: MouseEvent): T | null => {
      if (!(event.currentTarget instanceof Element)) {
        return null
      }

      const currentTargetRect = event.currentTarget.getBoundingClientRect()

      const offsetX = event.clientX - currentTargetRect.left
      const offsetY = event.clientY - currentTargetRect.top

      const bounds: [[number, number], [number, number]] = [
        [0, width],
        [0, height],
      ]

      // TODO: this transform is a bit redundant with transforms created
      // else where. Consider if we can simplify the parameters here, construct,
      // transforms in one place and pass that in.
      const transform = Transform.withDomainRange(extent, bounds)
        .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
        .translated(
          Math.max((width - height) / 2, 0),
          Math.max((height - width) / 2, 0),
        )
        .appending(
          new Transform(zoomTransform.x, zoomTransform.y, zoomTransform.k),
        )

      const point = transform.applyInverse([offsetX, offsetY])

      const radius = isTouchDevice() ? 2 : 0.5

      return searchTree.find(...point, radius / zoomTransform.k) ?? null
    },
    [extent, height, searchTree, width, zoomTransform],
  )

  return itemAtPoint
}
