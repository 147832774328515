import { Card } from '../SimulationState'

import { moveCards } from './moveCards'

export function distributeHalfLeft(cards: Card[]) {
  const pileCount = Math.max(...cards.map((c) => c.pile))

  moveCards(cards, pileCount, pileCount + 1, { fraction: 0.5 })
  for (let pileIndex = pileCount - 1; pileIndex >= 0; pileIndex--) {
    moveCards(cards, pileIndex, pileIndex + 1, { fraction: 0.5 })
  }

  moveCards(cards, pileCount + 1, 0)

  // Technically below would be "left" depending on how you're looking at it,
  // but the opposite direction makes it look more correct for how the piles are
  // positioned.

  // moveCards(cards, 0, pileCount + 1, { fraction: 0.5 })

  // for (let pileIndex = 1; pileIndex <= pileCount; pileIndex++) {
  //   moveCards(cards, pileIndex, pileIndex - 1, {
  //     fraction: 0.5,
  //   })
  // }

  // moveCards(cards, pileCount + 1, pileCount)

  return cards
}
