// title: Search the Archives - Lucky Paper Radio
// frameCardName: Searchlight Companion
// frameCardSet: neo

import * as styles from './search.module.scss'

import React, { useMemo, useState } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { sortBy } from 'lodash'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'

import { usePodcastSearch } from 'components/podcast/usePodcastSearch'

import { RadioGroup } from 'components/controls'
import { EpisodeRow } from 'components/podcast/EpisodeRow'
import { PodcastSearchForm } from 'components/podcast/SearchForm'
import { TimestampLinks } from 'components/podcast/timestamps/TimestampLinks'

interface Props extends PageProps<Queries.PodcastSearchPageQuery> {
  pageContext: {
    url: string
  }
}

const PodcastSearchPage: React.FC<Props> = (props) => {
  const { podcasts } = props.data.allMdx

  const query = useMemo(() => {
    return new URLSearchParams(props.location.search).get('q')
  }, [props.location])

  const [orderBy, setOrderBy] = useState<'relevance' | 'date'>('relevance')

  const searchResults = usePodcastSearch(podcasts, query)

  const sortedResults = useMemo(() => {
    if (orderBy === 'date') {
      return sortBy(searchResults, (result) =>
        result.episodeOrder != null ? result.episodeOrder * -1 : null,
      )
    }
    return searchResults
  }, [orderBy, searchResults])

  return (
    <Layout {...props.pageContext} accentColor="#4A3FC7">
      <div className={styles.header}>
        <div>
          <div className={styles.backLink}>
            <Link to="/podcast/">&larr; All Episodes</Link>
          </div>
          <div className={styles.pageTitle}>
            {query != null && query.length > 0 ? (
              <>Search Results for &ldquo;{query}&rdquo;</>
            ) : (
              <>Search Lucky Paper Radio</>
            )}
          </div>
        </div>

        <div className={styles.searchForm}>
          <PodcastSearchForm />

          <div className={styles.sortOptions}>
            <div>Sort</div>
            <RadioGroup
              options={[
                { name: 'relevance', label: 'Relevance' },
                { name: 'date', label: 'Date' },
              ]}
              value={orderBy}
              onChange={(event) => setOrderBy(event.currentTarget.value as any)}
              inline
            />
          </div>
        </div>
      </div>

      <div className={styles.results}>
        {sortedResults != null && sortedResults.length > 0 ? (
          sortedResults.map((podcast) => (
            <EpisodeRow podcast={podcast} key={podcast.id}>
              {podcast.matchedTimestamps.length > 0 && (
                <div className={styles.timestamps}>
                  <div className={styles.timestampsLabel}>
                    Relevant Timestamps
                  </div>

                  <TimestampLinks
                    episodeSlug={podcast.frontmatter!.episodeSlug!}
                    timestamps={podcast.matchedTimestamps}
                  />
                </div>
              )}
            </EpisodeRow>
          ))
        ) : query != null && query.length > 0 ? (
          <div className={styles.noResults}>No Episodes Found</div>
        ) : null}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PodcastSearchPage {
    allMdx(
      filter: { fields: { type: { eq: "podcast-notes" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      podcasts: nodes {
        id
        rawBody
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
          episodeOrder
          tags
          timestamps {
            time
            label
          }
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Search the Archives - Lucky Paper Radio',
        description:
          'Search all episodes of Lucky Paper Radio, a podcast about Magic: the Gathering and related topics. Find a particular episode or explore a topic including specifically relevant timestamps.',
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        rssFeed: {
          title: 'Lucky Paper Radio',
          url: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        },
      }}
    />
  )
}

export default PodcastSearchPage
