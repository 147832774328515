import { PageProps } from 'gatsby'
import { compact } from 'lodash'
import { useMemo } from 'react'

import { useSearch } from 'utils/useSearch'

const searchAttributes = ['title', 'excerpt', 'tags', 'bodyText']
const timestampSearchAttributes = ['label']

function stripTemplateBoilerplate(episodeText: string) {
  return episodeText
    .replace(/^---(.|\n)*\n---/, '')
    .replace(/\n(Check us out on|You can find the hosts)(.|\n)*$/, '')
    .replace('Discussed in this episode:', '')
}

/**
 * Pass a list of episodes and a search query, returns a list of episodes
 * matching the query.
 *
 * The title, tags, excerpt, body of the notes (with some boilerplate excluded),
 * and timestamps are searched.
 *
 * If the match is in one or more of the timestamps, the episode is returned
 * with a set of `matchedTimestamps`.
 */
export function usePodcastSearch(
  episodes: PageProps<Queries.PodcastSearchPageQuery>['data']['allMdx']['podcasts'],
  query: string | null,
) {
  const episodeSearchData = useMemo(() => {
    return episodes.map((episode) => ({
      ...episode,
      ...episode.frontmatter,
      bodyText: stripTemplateBoilerplate(episode.rawBody),
      timestampText: episode.frontmatter?.timestamps
        ?.map((t) => t?.label)
        .join('\n'),
      matchedTimestamps: [] as { time: string; label: string }[],
    }))
  }, [episodes])

  const episodeSearchResults = useSearch({
    data: episodeSearchData,
    query,
    idAttribute: 'id',
    searchAttributes,
  })

  const timestampSearchData = useMemo(() => {
    return compact(
      episodes.flatMap((episode) => {
        if (episode.frontmatter?.timestamps == null) {
          return []
        }

        return episode.frontmatter.timestamps.map((timestamp, index) =>
          timestamp?.label != null && timestamp.time != null
            ? {
                id: `${episode.id}-${index}`,
                episodeId: episode.id,
                time: timestamp.time,
                label: timestamp?.label,
              }
            : null,
        )
      }),
    )
  }, [episodes])

  const timestampResults = useSearch({
    data: timestampSearchData,
    query,
    idAttribute: 'id',
    searchAttributes: timestampSearchAttributes,
  })

  const searchResults = useMemo(() => {
    const result = episodeSearchResults ?? []

    if (timestampResults != null) {
      for (const timestampResult of timestampResults) {
        let episodeResult = result.find(
          (episode) => episode.id == timestampResult.episodeId,
        )

        if (episodeResult == null) {
          episodeResult = episodeSearchData.find(
            (episode) => episode.id == timestampResult.episodeId,
          )!

          result.push(episodeResult)
        }

        episodeResult.matchedTimestamps.push(timestampResult)
      }
    }

    return result
  }, [episodeSearchData, episodeSearchResults, timestampResults])

  return searchResults
}
