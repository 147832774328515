import * as styles from './EpisodeRow.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import { TagList } from './TagList'

interface Props {
  readonly podcast: {
    readonly id: string
    readonly frontmatter: {
      readonly title: string
      readonly date: string | null
      readonly excerpt: string | null
      readonly episodeSlug: string | null
      readonly episodeNumber: number | null
      readonly episodeOrder: number | null
      readonly tags: ReadonlyArray<string | null> | null
    } | null
  }
  activeTag?: string
  children?: React.ReactNode
}

export const EpisodeRow: React.FC<Props> = (props) => {
  const { podcast, activeTag, children } = props

  if (podcast.frontmatter == null) {
    return null
  }

  return (
    <div className={styles.episode} key={podcast.id}>
      <Link
        to={`/podcast/${podcast.frontmatter.episodeSlug}/`}
        className={styles.main}
      >
        {podcast.frontmatter.episodeNumber ? (
          <div className={styles.episodeNumber}>
            Episode {podcast.frontmatter.episodeNumber}
          </div>
        ) : (
          <div className={styles.bonus}>Bonus Episode</div>
        )}
        <div className={styles.title}>{podcast.frontmatter.title}</div>
        <div className={styles.date}>{podcast.frontmatter.date}</div>
      </Link>

      <div className={styles.details}>
        <div className={styles.excerpt}>{podcast.frontmatter.excerpt}</div>

        {podcast.frontmatter.tags != null &&
          podcast.frontmatter.tags?.length > 0 && (
            <div className={styles.tags}>
              <TagList tags={podcast.frontmatter.tags} activeTag={activeTag} />
            </div>
          )}

        {children != null && <div>{children}</div>}
      </div>

      <div className={styles.playButton}>▶&#xFE0E;</div>
    </div>
  )
}
