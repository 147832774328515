import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

export const Izzet: React.FC = () => {
  return (
    <Decklist
      name="Izzet 3-0 circa 2014"
      description={
        <>
          Even in a creature-light aggro deck, Heirloom Blade could easily
          replace the Sword in this maindeck.
        </>
      }
      maindeck={[
        [
          { name: 'Mountain', set: 'ody' },
          { name: 'Mountain', set: 'ody' },
          { name: 'Mountain', set: 'ody' },
          { name: 'Mountain', set: 'ody' },
          { name: 'Mountain', set: 'ody' },
          { name: 'Mountain', set: 'ody' },
          { name: 'Island', set: 'ody' },
          { name: 'Island', set: 'ody' },
          { name: 'Island', set: 'ody' },
          { name: 'Island', set: 'ody' },
          { name: 'Steam Vents' },
          { name: 'Shivan Reef' },
          { name: 'Gemstone Mine' },
          { name: 'Mox Jet' },
          { name: 'Scalding Tarn' },
          { name: 'Library of Alexandria' },
        ],

        [
          { name: 'Firebolt' },
          { name: 'Lightning Bolt' },
          { name: 'Rakdos Cackler' },
          { name: 'Goblin Guide' },
          { name: 'Legion Loyalist' },
          { name: 'Tattermunge Maniac' },
        ],

        [
          { name: 'Searing Blood' },
          { name: 'Searing Blaze' },
          { name: 'Remand' },
          { name: 'Riftwing Cloudskate' },
          { name: 'Phyrexian Revoker' },
          { name: 'Coralhelm Commander' },
        ],

        [
          { name: 'Wheel of Fortune' },
          { name: 'Phyrexian Metamorph' },
          { name: 'Dualcaster Mage' },
          { name: 'Staggershock' },
          { name: 'Dack Fayden' },
          { name: 'Vendilion Clique' },
          { name: 'Goblin Rabblemaster' },
          { name: 'Grafted Wargear' },
          { name: 'Sword of Body and Mind' },
        ],

        [
          { name: 'Control Magic' },
          { name: 'Venser, Shaper Savant' },
          { name: "Dack's Duplicate" },
          { name: 'Upheaval' },
        ],
      ]}
    />
  )
}
