import { random, sortBy } from 'lodash'

import { Card } from '../SimulationState'

import { countPile } from './countPile'

/**
 * Exchange a number or fraction of cards between two piles.
 */
export function swapCards(
  cards: Card[],
  sourcePile: number,
  destinationPile: number,
  options: {
    count?: number
    fraction?: number
  } = {},
) {
  let sourceCount =
    options.count != null
      ? options.count
      : options.fraction != null
      ? Math.ceil(countPile(cards, sourcePile) * options.fraction)
      : null

  let destinationCount =
    options.count != null
      ? options.count
      : options.fraction != null
      ? Math.ceil(countPile(cards, destinationPile) * options.fraction)
      : null

  if (sourceCount != null) {
    sourceCount += random(-3, 3, false)
  }

  if (destinationCount != null) {
    destinationCount += random(-3, 3, false)
  }

  const collectionIndices = cards.reduce(
    (result, card, index) => {
      if (card.pile == sourcePile) {
        result.source.push({ index, position: card.position })
      }
      if (card.pile == destinationPile) {
        result.dest.push({ index, position: card.position })
      }

      return result
    },
    {
      source: [] as { index: number; position: number }[],
      dest: [] as { index: number; position: number }[],
    },
  )

  const sourceCollectionIndicesToMove = sortBy(
    collectionIndices.source,
    (c) => c.position,
  )
    .slice(sourceCount != null ? -1 * sourceCount : 0)
    .map((c) => c.index)

  const destCollectionIndicesToMove = sortBy(
    collectionIndices.dest,
    (c) => c.position,
  )
    .slice(destinationCount != null ? -1 * destinationCount : 0)
    .map((c) => c.index)

  const sourceRemainderPileCount =
    collectionIndices.source.length - sourceCollectionIndicesToMove.length
  const destRemainderPileCount =
    collectionIndices.dest.length - destCollectionIndicesToMove.length

  for (const [
    index,
    collectionIndex,
  ] of sourceCollectionIndicesToMove.entries()) {
    cards[collectionIndex].pile = destinationPile
    cards[collectionIndex].position = destRemainderPileCount + index
  }

  for (const [
    index,
    collectionIndex,
  ] of destCollectionIndicesToMove.entries()) {
    cards[collectionIndex].pile = sourcePile
    cards[collectionIndex].position = sourceRemainderPileCount + index
  }

  return cards
}
