import React from 'react'

export const IconReset: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={30}
      height={30}
    >
      <g>
        <path
          style={{ stroke: 'currentColor', strokeWidth: 2.5 }}
          fill="none"
          d="m8.04,8.64c.94-1.03,2.12-1.86,3.5-2.41,4.84-1.91,10.32.47,12.23,5.31,1.91,4.84-.47,10.32-5.31,12.23-3.87,1.53-8.15.31-10.68-2.71"
        />
        <polygon
          fill="currentColor"
          points="12.71 10.92 3.2 13.94 5.34 4.2 12.71 10.92"
        />
      </g>
    </svg>
  )
}
