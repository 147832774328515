import * as styles from './PilesSVG.module.scss'

import React, { useMemo } from 'react'
import classNames from 'classnames'
import { range } from 'lodash'
import * as d3 from 'd3'

import { SimulationState } from '../data/SimulationState'

const cardWidth = 1
const markerWidth = 2

interface Props {
  simulationState: SimulationState
  x: number
  y: number
  width: number
  height: number
  animating: boolean
  alternatingRows?: boolean
}

export const PilesSVG: React.FC<Props> = (props) => {
  const {
    simulationState,
    x,
    y,
    width,
    animating,
    alternatingRows = false,
  } = props

  const {
    cards,
    pileCounts,
    pilePositions,
    columns: columnCount,
  } = simulationState

  const columnGap = 10
  const rowGap = 20

  const columnWidth = useMemo(() => {
    return Math.min((width - (columnCount - 1) * columnGap) / columnCount, 120)
  }, [columnCount, width])

  const rowHeight = useMemo(() => {
    return Math.max(...pileCounts) * cardWidth
  }, [pileCounts])

  const layoutWidth = columnCount * columnWidth + (columnCount - 1) * columnGap

  const inset = (width - layoutWidth) / 2

  const pileLocations = useMemo(() => {
    return range(0, pilePositions.length).map((index) => {
      const y = Math.floor(index / columnCount)
      const x =
        !alternatingRows || y % 2 === 0
          ? index % columnCount
          : columnCount - (index % columnCount) - 1

      return [
        x * (columnWidth + columnGap),
        (y % columnCount) * (rowHeight + rowGap),
      ]
    })
  }, [
    alternatingRows,
    columnCount,
    columnWidth,
    pilePositions.length,
    rowHeight,
  ])

  const markerScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, 1])
      .range([2, columnWidth - markerWidth - 2])
  }, [columnWidth])

  return (
    <g
      className={classNames(styles.container, {
        [styles.animating]: animating,
      })}
      transform={`translate(${x + inset}, ${y})`}
    >
      {cards.map((card) => (
        <g
          key={card.id}
          className={styles.card}
          transform={`translate(${pileLocations[card.pile][0]}, ${
            pileLocations[card.pile][1] + rowHeight - card.position * cardWidth
          })`}
        >
          <rect
            x={0}
            y={0}
            width={columnWidth}
            height={cardWidth}
            fill={card.color}
          />

          <rect
            x={markerScale(card.fractionalPosition)}
            y={0}
            width={markerWidth}
            height={cardWidth}
            fill="#ffffff"
          />
        </g>
      ))}
    </g>
  )
}
