import * as styles from './ShufflerLayout.module.scss'

import React from 'react'

import { formatDuration } from 'utils/formatDuration'

import * as Typography from 'components/typography'

import { SimulationState } from '../data/SimulationState'
import { PlaybackControls } from './PlaybackControls'

interface Props {
  simulationState?: SimulationState
  title?: string
  caption?: string
  controls?: React.ReactNode
  children: React.ReactNode
  playing: boolean
  actions: {
    play: () => void
    pause: () => void
    reset: () => void
  }
}

export const ShufflerLayout: React.FC<Props> = (props) => {
  const {
    simulationState,
    title,
    controls,
    children,
    caption,
    playing,
    actions,
  } = props

  return (
    <div className={styles.container}>
      <Typography.TextColumn>
        <div className={styles.header}>
          <PlaybackControls playing={playing} {...actions} />

          {title != null && <div className={styles.title}>{title}</div>}

          {controls != null && (
            <div className={styles.controls}>{controls}</div>
          )}
        </div>
      </Typography.TextColumn>

      {children}

      <div>
        {simulationState != null && (
          <Typography.TextColumn>
            <div className={styles.stats}>
              <div>Riffles: {simulationState.riffles}</div>
              <div>Remixes: {simulationState.remixes}</div>
              <div>Time ≈ {formatDuration(simulationState.time)}</div>
            </div>
          </Typography.TextColumn>
        )}

        {caption != null && <Typography.Caption>{caption}</Typography.Caption>}
      </div>
    </div>
  )
}
