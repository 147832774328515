import { useCallback, useEffect, useMemo, useState } from 'react'
import { uniqueId } from 'lodash'

import { useArticleSetting } from 'components/article-elements/ArticleSettingsContext'

/**
 * The "playing" state for an animation which will automatically pause if
 * another instance of the hook on the page starts playing. i.e. manage multiple
 * mutually exclusive animations such that no more than one is playing at a
 * time.
 *
 * Stores the state in the 'article settings'  context so will only work if
 * wrapped in that provider.
 *
 * Optionally provide an ID to scope multiple animations enabled at once.
 */
export function useAnimationMutex(id?: string) {
  const animationID = useMemo(() => {
    return id ?? uniqueId()
  }, [id])

  const [playing, setPlaying] = useState(false)

  const [activeAnimationID, setActiveAnimationID] = useArticleSetting<string>(
    'active-animation-id',
  )

  const play = useCallback(() => {
    setActiveAnimationID(animationID)
    setPlaying(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pause = useCallback(() => {
    setPlaying(false)
  }, [])

  useEffect(() => {
    if (playing && activeAnimationID !== animationID) {
      setPlaying(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnimationID, animationID])

  return {
    playing,
    play,
    pause,
  }
}
