export interface ProgramInfo<Attribute extends string, Uniform extends string> {
  context: WebGLRenderingContext
  program: WebGLProgram
  attributes: Record<Attribute, number>
  uniforms: Record<Uniform, WebGLUniformLocation>
}

/**
 * Compiles a WebGL program given the vertex and fragment shaders, looks up
 * attribute and uniform locations, and returns an object with these values.
 */
export const buildWebGLProgram = <
  Attribute extends string,
  Uniform extends string,
>(
  context: WebGLRenderingContext,
  vertexShaderSource: string,
  fragmentShaderSource: string,
  attributeNames: Attribute[],
  uniformNames: Uniform[],
): ProgramInfo<Attribute, Uniform> => {
  const vertexShader = context.createShader(context.VERTEX_SHADER)

  if (vertexShader == null) {
    throw new Error('Failed to create vertex shader')
  }

  context.shaderSource(vertexShader, vertexShaderSource)

  context.compileShader(vertexShader)

  if (!context.getShaderParameter(vertexShader, context.COMPILE_STATUS)) {
    // eslint-disable-next-line no-console
    console.error(context.getShaderInfoLog(vertexShader))
  }

  const fragmentShader = context.createShader(context.FRAGMENT_SHADER)

  if (fragmentShader == null) {
    throw new Error('Failed to create fragment shader')
  }

  context.shaderSource(fragmentShader, fragmentShaderSource)

  context.compileShader(fragmentShader)

  if (!context.getShaderParameter(fragmentShader, context.COMPILE_STATUS)) {
    // eslint-disable-next-line no-console
    console.error(context.getShaderInfoLog(fragmentShader))
  }

  const program = context.createProgram()

  if (program == null) {
    throw new Error('Failed to create shader program')
  }

  context.attachShader(program, vertexShader)
  context.attachShader(program, fragmentShader)
  context.linkProgram(program)

  const status = context.getProgramParameter(program, context.LINK_STATUS)
  if (!status) {
    // eslint-disable-next-line no-console
    console.error(
      `couldn't link shader program:\n${context.getProgramInfoLog(program)}`,
    )
  }

  const attributes = attributeNames.reduce((result, attribute) => {
    result[attribute] = context.getAttribLocation(program, attribute)
    return result
  }, {} as Record<Attribute, number>)

  const uniforms = uniformNames.reduce((result, uniform) => {
    const location = context.getUniformLocation(program, uniform)
    if (location != null) {
      result[uniform] = location
    }
    return result
  }, {} as Record<Uniform, WebGLUniformLocation>)

  return { context, program, attributes, uniforms }
}
