import React from 'react'
// import { random } from 'lodash'

import { buildInitialCards } from '../data/SimulationState'

import { FullCube } from '../components/FullCube'
// import { twoDistinctRandoms } from '../data/twoDistinctRandoms'
// import * as transformations from '../data/transformations'

const positions = [
  235, 237, 166, 157, 230, 39, 142, 135, 147, 247, 171, 241, 38, 168, 28, 146,
  132, 2, 172, 255, 169, 161, 144, 139, 249, 220, 225, 226, 245, 258, 227, 145,
  143, 137, 167, 140, 243, 7, 246, 138, 27, 149, 134, 24, 114, 126, 41, 92, 53,
  105, 127, 116, 102, 95, 100, 88, 110, 49, 93, 111, 71, 97, 65, 63, 43, 99, 57,
  66, 50, 68, 80, 75, 45, 77, 81, 46, 108, 90, 115, 106, 69, 104, 96, 85, 59,
  60, 62, 118, 61, 119, 52, 98, 112, 86, 89, 91, 94, 44, 128, 55, 58, 121, 103,
  84, 64, 48, 124, 78, 117, 83, 56, 109, 79, 72, 74, 122, 51, 125, 123, 101, 42,
  73, 54, 70, 47, 82, 76, 113, 107, 120, 67, 87, 165, 155, 240, 164, 136, 250,
  163, 162, 133, 231, 148, 130, 150, 173, 36, 224, 3, 12, 22, 252, 29, 11, 223,
  23, 152, 160, 141, 131, 25, 219, 158, 170, 129, 6, 26, 174, 154, 34, 229, 256,
  159, 153, 151, 156, 217, 202, 211, 193, 201, 190, 175, 197, 184, 182, 183,
  187, 189, 218, 203, 181, 180, 207, 185, 196, 198, 204, 208, 206, 195, 186,
  177, 215, 209, 179, 205, 199, 200, 210, 192, 191, 216, 214, 176, 194, 213,
  212, 178, 188, 0, 17, 257, 239, 244, 259, 238, 233, 30, 19, 18, 4, 1, 8, 40,
  10, 251, 15, 228, 9, 5, 33, 232, 20, 242, 221, 248, 234, 32, 35, 13, 31, 222,
  254, 21, 236, 14, 37, 253, 16,
]

const cards = (() => {
  const cardCount = 260
  const piles = 6

  const cards = buildInitialCards(cardCount, piles)

  for (const [index, card] of cards.entries()) {
    card.pile = 0
    card.position = positions[index]
  }

  // To generate a new randomized list.

  // for (let index = 0; index < 30; index++) {
  //   if (random(0, 5, false) === 0) {
  //     const [source, destination] = twoDistinctRandoms(0, piles - 1)

  //     const result = transformations.swapCards(cards, source, destination, {
  //       fraction: 0.5,
  //     })

  //     for (let i = 0; i < piles; i++) {
  //       if (i !== source && i != destination) {
  //         transformations.rifflePile(result, i)
  //       }
  //     }

  //     cards = result
  //   } else {
  //     cards = transformations.riffleAllPiles([...cards])
  //   }
  // }

  // for (let index = 1; index < piles; index++) {
  //   transformations.moveCards(cards, index, 0)
  // }

  // console.log(cards.map((card) => card.position).join(', '))

  return cards
})()

const simulationState = {
  cards,
  pileCounts: [cards.length],
  pilePositions: [0],
  columns: 1,
}

export const ExamplePartiallyShuffled: React.FC = () => {
  return <FullCube simulationState={simulationState} />
}
