// extracted by mini-css-extract-plugin
export var bonus = "EpisodeRow-module--bonus--e349b";
export var date = "EpisodeRow-module--date--68a3a";
export var details = "EpisodeRow-module--details--c04f1";
export var episode = "EpisodeRow-module--episode--1ba83";
export var episodeNumber = "EpisodeRow-module--episodeNumber--ede10";
export var excerpt = "EpisodeRow-module--excerpt--d6619";
export var main = "EpisodeRow-module--main--e733f";
export var playButton = "EpisodeRow-module--playButton--f4448";
export var tags = "EpisodeRow-module--tags--1d2c1";
export var title = "EpisodeRow-module--title--7e6c7";