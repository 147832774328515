import { useCallback, useEffect, useMemo, useRef } from 'react'

import * as transformations from '../data/transformations'
import { useAnimationMutex } from '../data/useAnimationMutex'
import { useSimulationState, Card } from '../data/SimulationState'

export function usePassLeftAnimation(
  options: {
    cardCount?: number
    piles?: number
    columns?: number
    shufflesPerPile?: number
    animationID?: string
  } = {},
) {
  const {
    cardCount = 540,
    piles = 4,
    columns = 12,
    shufflesPerPile = 3,
    animationID,
  } = options
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const { playing, play, pause } = useAnimationMutex(animationID)

  const stateRef = useRef(0)

  const { simulationState, updateSimulationState, resetState } =
    useSimulationState({ cardCount, piles, columns })

  const reset = useCallback(() => {
    stateRef.current = 0
    pause()
    resetState()
  }, [pause, resetState])

  useEffect(() => {
    stateRef.current = 0
    pause()
    resetState()
  }, [piles, cardCount, pause, shufflesPerPile, resetState])

  // Build an array of all the steps of the animation. Each step has a function
  // to mutate the simulation state, timing info to update the activity being
  // tracked, and an animation duration to trigger the next step.
  const steps = useMemo(() => {
    const result = []

    // Riffle all piles simultaneously?
    // for (let shuffleIndex = 0; shuffleIndex < shufflesPerPile; shuffleIndex++) {
    //   result.push({
    //     function: (cards: Card[]) => transformations.riffleAllPiles(cards),
    //     animationDuration: shuffleIndex === shufflesPerPile - 1 ? 500 : 200,
    //   })
    // }

    // Or do one at a time to make the animations nice?
    for (let shuffleIndex = 0; shuffleIndex < shufflesPerPile; shuffleIndex++) {
      for (let pileIndex = 0; pileIndex < piles; pileIndex++) {
        const lastShuffle =
          shuffleIndex === shufflesPerPile - 1 && pileIndex == piles - 1
        result.push({
          function: (cards: Card[]) =>
            transformations.rifflePile(cards, pileIndex),
          animationDuration: lastShuffle ? 500 : 0,
          time: 15 / piles,
          riffles: 1,
          remixes: 0,
        })
      }
    }

    result.push({
      function: (cards: Card[]) => transformations.distributeHalfLeft(cards),
      animationDuration: 500,
      time: 25,
      riffles: 0,
      remixes: 1,
    })

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [piles])

  useEffect(() => {
    if (playing) {
      const action = () => {
        const state = stateRef.current

        const step = steps[state % steps.length]

        updateSimulationState(step.function([...simulationState.cards]), step)

        stateRef.current++

        timeoutIDRef.current = setTimeout(action, step.animationDuration)
      }

      action()

      return () => {
        timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
      }
    } else {
      timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing])

  return {
    playing,
    simulationState,
    actions: {
      play,
      pause,
      reset,
    },
  }
}
