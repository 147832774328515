import React, { useCallback, useContext, useState } from 'react'

const defaultArticleSettings = {
  settings: {
    tmp: false,
  },
  setSettings: () => {
    /* no-op */
  },
}

export const ArticleSettingsContext = React.createContext<{
  settings: Record<string, unknown>
  setSettings: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}>(defaultArticleSettings)

interface Props {
  children: React.ReactNode
}

export const ArticleSettingsProvider: React.FC<Props> = (props) => {
  const { children } = props

  const [settings, setSettings] = useState<Record<string, unknown>>({})

  return (
    <ArticleSettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      {children}
    </ArticleSettingsContext.Provider>
  )
}

/**
 * Context for arbitrary settings for components embedded in an article intended
 * for e.g. settings on graphs that should be effect all components on the page.
 */
export const useArticleSetting = <T,>(key: string) => {
  const { settings, setSettings } = useContext(ArticleSettingsContext)

  const updateSetting = useCallback(
    (value: Record<string, unknown>) => {
      setSettings((previousSettings) => ({ ...previousSettings, [key]: value }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  )

  return [settings[key], updateSetting] as [T | null, (value: T | null) => void]
}
