import React from 'react'

export const IconPause: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={30}
      height={30}
    >
      <g>
        <rect
          fill="currentColor"
          x="7.95"
          y="6.83"
          width="4.72"
          height="16.34"
        />
        <rect
          fill="currentColor"
          x="17.33"
          y="6.83"
          width="4.72"
          height="16.34"
        />
      </g>
    </svg>
  )
}
