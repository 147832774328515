import React, { useMemo } from 'react'
import * as d3 from 'd3'

import { SimulationState } from '../data/SimulationState'

const markerWidth = 2

interface Props {
  simulationState: Pick<SimulationState, 'cards' | 'pilePositions'>
  x?: number
  y?: number
  width: number
  height: number
}

export const FullCubeSVG: React.FC<Props> = (props) => {
  const { simulationState, x = 0, y = 0, width, height } = props

  const { cards, pilePositions } = simulationState

  const scaleX = useMemo(() => {
    return d3.scaleLinear().domain([0, cards.length]).range([0, width])
  }, [cards.length, width])

  const cardWidth = useMemo(() => {
    return width / cards.length
  }, [width, cards.length])

  const markerScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, 1])
      .range([height - markerWidth - 2, 2])
  }, [height])

  return (
    <g transform={`translate(${x}, ${y})`}>
      {cards.map((card) => (
        <g
          key={card.id}
          transform={`translate(${scaleX(
            pilePositions[card.pile] + card.position,
          )}, 0)`}
        >
          <rect
            x={0}
            y={0}
            width={cardWidth}
            height={height}
            fill={card.color}
          />
          <rect
            x={0}
            y={markerScale(card.fractionalPosition)}
            width={cardWidth}
            height={markerWidth}
            fill="#ffffff"
          />
        </g>
      ))}
    </g>
  )
}
