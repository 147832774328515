import { useCallback, useEffect, useMemo, useState } from 'react'
import * as JSSearch from 'js-search'
import { orderBy } from 'lodash'

// Using discouraged `Object` to match the types in js-search.
// eslint-disable-next-line @typescript-eslint/ban-types
export const useSearch = <T extends Object>(options: {
  data: T[]
  query: string | null
  idAttribute: string
  searchAttributes: (string | string[])[]
  sortByAttribute?: string
  max?: number
}): T[] | null => {
  const { data, query, idAttribute, searchAttributes, sortByAttribute, max } =
    options

  const searchIndex = useMemo(() => {
    const searchIndex = new JSSearch.Search(idAttribute)
    searchAttributes.forEach((attribute) => {
      searchIndex.addIndex(attribute)
    })
    searchIndex.addDocuments(data)
    return searchIndex
  }, [data, idAttribute, searchAttributes])

  const search = useCallback(
    (query: string) => {
      let results = searchIndex.search(query) as any

      if (max != null) {
        results = results.slice(0, max)
      }

      if (sortByAttribute != null) {
        results = orderBy(results, sortByAttribute, 'desc')
      }

      return results
    },
    [max, searchIndex, sortByAttribute],
  )

  const [results, setResults] = useState<T[] | null>(null)

  useEffect(() => {
    if (query != null) {
      setResults(search(query))
    } else {
      setResults(null)
    }
  }, [query, search])

  return results
}
