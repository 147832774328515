import React, { useCallback } from 'react'
import { compact } from 'lodash'

import { parseDuration } from 'utils/parseDuration'

import { usePlayerState } from '../PlayerContext'

import Timestamp from './Timestamp'

interface Props {
  timestamps:
    | readonly ({
        label: string | null
        time: string | null
      } | null)[]
    | null
  interactive: boolean
}

/**
 * Lists timestamps with each navigating playback of the audio player on the
 * page to the time.
 */
export const TimestampsPlaybackNavigation: React.FC<Props> = (props) => {
  const { timestamps, interactive } = props

  const { setSeekToSeconds } = usePlayerState()

  const seekToTimestamp = useCallback(
    (time: string) => {
      if (setSeekToSeconds != null) {
        setSeekToSeconds(parseDuration(time))
      }
    },
    [setSeekToSeconds],
  )

  if (timestamps == null) {
    return null
  }

  return (
    <div>
      {compact(timestamps).map(({ time, label }, index) =>
        label != null && time != null ? (
          <Timestamp
            key={time}
            time={time}
            label={label}
            first={index === 0}
            onClick={() => {
              seekToTimestamp(time)
            }}
            interactive={interactive}
          />
        ) : null,
      )}
    </div>
  )
}
