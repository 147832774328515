import * as styles from './Label.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  title: string
  children: React.ReactNode
  inline?: boolean
}

export const Label: React.FC<Props> = (props) => {
  const { title, children, inline = false } = props

  return (
    <label
      className={classNames(styles.container, {
        [styles.inline]: inline,
      })}
    >
      <div className={styles.label}>{title}</div>
      {children}
    </label>
  )
}
