import { uniq } from 'lodash'

import { Card } from '../SimulationState'

import { rifflePile } from './rifflePile'

/**
 * Riffle shuffle all the cards in each pile.
 */
export function riffleAllPiles(cards: Card[]) {
  const piles = uniq(cards.map((c) => c.pile))

  for (const pile of piles) {
    cards = rifflePile(cards, pile)
  }

  return cards
}
