import React from 'react'

const ArtCredit: React.FC = () => {
  return (
    <svg
      focusable="false"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '1em',
        height: '1em',
        display: 'inline',
        verticalAlign: '-0.1em',
      }}
    >
      <g fill="currentColor">
        <path d="M9.46,4.37c-1.82-.09-3.94,.37-4.95,3.37-.84-1.72-3-2.92-4.09-2.9V15.2c1.17-.18,3.01-1.07,4.11-2.96,.35,1.27,1.64,3.41,4.38,3.37,3.77-.05,7.91-3.2,10.78-5.63-4.28-3.63-7.86-5.49-10.24-5.61Zm-4.59,5.37c.45-2.14,1.21-3.66,2.39-4.22s2.56-.46,4.53,.33,3.01,1.26,6.6,4.03c-7.92,2.95-8.71-3.23-13.52-.14Z" />
      </g>
    </svg>
  )
}

export default ArtCredit
