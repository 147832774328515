/**
 * A linear 2d transformation including scaling and translating.
 */
export class Transform {
  x: number
  y: number
  scaleX: number
  scaleY: number

  constructor(x = 0, y = 0, scaleX = 1, scaleY: number | null = null) {
    this.x = x
    this.y = y
    this.scaleX = scaleX
    this.scaleY = scaleY === null ? scaleX : scaleY
  }

  /**
   * Creates a new transformation from an input range and output domain for
   * transforming points between coordinate spaces.
   */
  static withDomainRange = (
    domain: [[number, number], [number, number]],
    range: [[number, number], [number, number]],
  ) => {
    const domainDeltaX = domain[0][1] - domain[0][0]
    const rangeDeltaX = range[0][1] - range[0][0]
    const scaleX = rangeDeltaX / domainDeltaX
    const translateX = -domain[0][0] * scaleX + range[0][0]

    const domainDeltaY = domain[1][1] - domain[1][0]
    const rangeDeltaY = range[1][1] - range[1][0]
    const scaleY = rangeDeltaY / domainDeltaY
    const translateY = -domain[1][0] * scaleY + range[1][0]

    return new Transform(translateX, translateY, scaleX, scaleY)
  }

  /**
   * Returns a new transform translated by the specified amounts.
   */
  translated = (x: number, y: number) => {
    return new Transform(this.x + x, this.y + y, this.scaleX, this.scaleY)
  }

  /**
   * Returns a new transform scaled by the given amounts. Pass only one
   * parameter for uniform scale in both axes.
   */
  scaled = (x: number, y?: number) => {
    return new Transform(
      this.x * x,
      this.y * (y === undefined ? x : y),
      this.scaleX * x,
      this.scaleY * (y === undefined ? x : y),
    )
  }

  /**
   * Returns a new transform with the given transform appended to it such that
   * applying the result to a point is the same as applying both transforms.
   */
  appending = (transform: Transform): Transform => {
    const scaleX = this.scaleX * transform.scaleX
    const scaleY = this.scaleY * transform.scaleY
    const translationX = this.x * transform.scaleX + transform.x
    const translationY = this.y * transform.scaleY + transform.y

    return new Transform(translationX, translationY, scaleX, scaleY)
  }

  /**
   * Rescales a transformation from an input domain to an output range.
   * Equivalent to creating a new transform from two coordinate spaces and
   * appending it to the receiver.
   */
  rescaled = (
    domain: [[number, number], [number, number]],
    range: [[number, number], [number, number]],
  ): Transform => {
    return this.appending(Transform.withDomainRange(domain, range))
  }

  /**
   * Scale a point. Returns a new coordinate instance.
   */
  apply = (coordinate: [number, number]): [number, number] => {
    return [
      coordinate[0] * this.scaleX + this.x,
      coordinate[1] * this.scaleY + this.y,
    ]
  }

  applyInverse = (coordinate: [number, number]): [number, number] => {
    return [
      (coordinate[0] - this.x) / this.scaleX,
      (coordinate[1] - this.y) / this.scaleY,
    ]
  }

  toString = () => {
    return `translate(${this.x},${this.y}) scale(${this.scaleX}, ${this.scaleY})`
  }

  components = (): [number, number, number, number] => {
    return [this.x, this.y, this.scaleX, this.scaleY]
  }
}
