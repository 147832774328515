import React, { useState } from 'react'

import * as controls from 'components/controls'

import { usePassLeftAnimation } from './usePassLeftAnimation'

import { ShufflerLayout } from '../components/ShufflerLayout'
import { ShuffleVisualization } from '../components/ShuffleVisualization'

const cardCount = 540

export const ShuffleSimulatorPassLeft: React.FC = () => {
  const [piles, setPiles] = useState(8)
  const [shuffles, setShuffles] = useState(3)

  const { playing, simulationState, actions } = usePassLeftAnimation({
    cardCount,
    piles,
    columns: piles / 2,
    shufflesPerPile: shuffles,
  })

  return (
    <ShufflerLayout
      simulationState={simulationState}
      playing={playing}
      actions={actions}
      title="Pass Left"
      caption="Players shuffle a portion of the cube. Periodically, each player passes half their stack left."
      controls={
        <controls.ControlGroup>
          <controls.Select
            value={piles.toString()}
            options={[
              { name: '4', label: '4 Piles' },
              { name: '6', label: '6 Piles' },
              { name: '8', label: '8 Piles' },
            ]}
            onChange={(event) => {
              setPiles(parseInt(event.currentTarget.value))
            }}
          />

          <controls.Select
            value={shuffles.toString()}
            options={[
              { name: '1', label: '1 Shuffles / Pass' },
              { name: '2', label: '2 Shuffles / Pass' },
              { name: '3', label: '3 Shuffles / Pass' },
              { name: '5', label: '5 Shuffles / Pass' },
              { name: '7', label: '7 Shuffles / Pass' },
              { name: '10', label: '10 Shuffles / Pass' },
              { name: '20', label: '20 Shuffles / Pass' },
            ]}
            onChange={(event) => {
              setShuffles(parseInt(event.currentTarget.value))
            }}
          />
        </controls.ControlGroup>
      }
    >
      <ShuffleVisualization
        simulationState={simulationState}
        animating={playing}
        height={(cardCount / piles) * 2 + 50}
        alternatingRows
      />
    </ShufflerLayout>
  )
}
