import * as styles from './ShuffleVisualization.module.scss'

import React from 'react'

import { ResponsiveSVG } from 'components/utils/ResponsiveSVG'
import { TextColumn } from 'components/typography'

import { SimulationState } from '../data/SimulationState'

import { FullCubeSVG } from './FullCubeSVG'
import { PilesSVG } from './PilesSVG'

interface Props {
  simulationState: SimulationState
  animating?: boolean
  height: number
  alternatingRows?: boolean
}

export const ShuffleVisualization: React.FC<Props> = (props) => {
  const { simulationState, animating = false, height, alternatingRows } = props

  const padding = 0

  return (
    <div>
      <div className={styles.fullCube}>
        <TextColumn>
          <ResponsiveSVG height="40px">
            {({ width, height }) => (
              <FullCubeSVG
                simulationState={simulationState}
                x={0}
                y={0}
                width={width}
                height={height}
              />
            )}
          </ResponsiveSVG>
        </TextColumn>
      </div>

      <div className={styles.piles}>
        <ResponsiveSVG height={`${height}px`}>
          {({ width, height }) => (
            <PilesSVG
              simulationState={simulationState}
              x={padding}
              y={padding}
              width={width - padding * 2}
              height={height - padding * 2 - 40}
              animating={animating}
              alternatingRows={alternatingRows}
            />
          )}
        </ResponsiveSVG>
      </div>
    </div>
  )
}
