import { useCallback, useEffect, useRef } from 'react'
import { random } from 'lodash'

import * as transformations from '../data/transformations'
import { twoDistinctRandoms } from '../data/twoDistinctRandoms'
import { useAnimationMutex } from '../data/useAnimationMutex'
import { useSimulationState } from '../data/SimulationState'

export function useChaosAnimation(
  options: {
    cardCount?: number
    piles?: number
    columns?: number
    animationID?: string
  } = {},
) {
  const { cardCount = 540, piles = 4, columns = 12, animationID } = options
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const { playing, play, pause } = useAnimationMutex(animationID)

  const stateRef = useRef(0)

  const { simulationState, updateSimulationState, resetState } =
    useSimulationState({ cardCount, piles, columns })

  const reset = useCallback(() => {
    stateRef.current = 0
    pause()
    resetState()
  }, [pause, resetState])

  useEffect(() => {
    if (playing) {
      const action = () => {
        if (random(0, 8, false) === 0) {
          const [source, destination] = twoDistinctRandoms(0, piles - 1)

          const result = transformations.swapCards(
            [...simulationState.cards],
            source,
            destination,
            {
              fraction: 0.5,
            },
          )

          for (let i = 0; i < piles; i++) {
            if (i !== source && i != destination) {
              transformations.rifflePile(result, i)
            }
          }
          updateSimulationState(result, {
            time: 15,
            riffles: piles - 2,
            remixes: 1,
          })
        } else {
          updateSimulationState(
            transformations.riffleAllPiles([...simulationState.cards]),
            {
              time: 15,
              riffles: piles,
            },
          )
        }

        stateRef.current++

        timeoutIDRef.current = setTimeout(action, 400)
      }

      action()

      return () => {
        timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
      }
    } else {
      timeoutIDRef.current != null && clearTimeout(timeoutIDRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing])

  return {
    playing,
    simulationState,
    actions: {
      play,
      pause,
      reset,
    },
  }
}
